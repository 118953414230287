import React from 'react'
import bem from 'bem'
import css from './DbList.module.scss'
import PropTypes from 'prop-types'
import Product from 'components/Product'

const b = bem.dbList(css)

const DbList = props => {
	const { dbs, negative, partnerId } = props
	return (
		<div className={b()}>
			{dbs.map(db => {
				const { title, name, description, path, price, priceOld, links, stats } = db
				return (
					<div className={b('db')} key={`db-${name}`}>
						<Product
							className={b('product')}
							urlPage={path}
							urlExample={links.example.russia}
							type={name}
							icon={name}
							title={title}
							description={description}
							price={price}
							priceOld={priceOld}
							negative={negative}
							stats={stats}
							partnerId={partnerId}
						/>
					</div>
				)
			})}
		</div>
	)
}

DbList.propTypes = {
	negative: PropTypes.bool,
	dbs: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
			price: PropTypes.number.isRequired,
			priceOld: PropTypes.number,
			links: PropTypes.shape({
				example: PropTypes.shape({
					russia: PropTypes.string.isRequired,
				}).isRequired,
			}).isRequired,
		})
	),

	// ID партнера, переданного через iframe
	partnerId: PropTypes.number,
}

export default DbList
